import React, { useEffect, useState } from 'react';
import styles from './footer.module.scss';
import { Container, Row, Col } from 'react-grid-system';
import { environment } from '@environment';
import { getOS, setLanguageCookies, updateQueryString } from '@helpers/helper';
import { useTranslation } from 'next-i18next';
import { userContext } from '@components/auth/auth.context';
import { AmplitudeEvents } from '@enums/amplitude';
import reportAmplitudeEvent from '@helpers/analytics';
import AsyncImage from '@components/async-image/async-image';

const Footer = () => {
	const auth = React.useContext(userContext);
	const { t, i18n } = useTranslation();
	const lang = i18n.language;
	const [isPortrait, setIsPortrait] = useState(false);

	const androidLink = 'https://play.google.com/store/apps/details?id=com.anghami&hl=en';
	const iosLink =
		'https://itunes.apple.com/us/app/anghami-%D8%A7%D9%86%D8%BA%D8%A7%D9%85%D9%8A/id545395155?mt=8';
	const desktopAppLink = 'https://play.anghami.com/download';

	const onButtonClick = () => {
		const os = getOS();
		let redirectUrl = '';
		if (os === 'iphone') {
			redirectUrl = iosLink;
		}

		if (os === 'android') {
			redirectUrl = androidLink;
		}

		if (os === 'web') {
			redirectUrl = desktopAppLink;
		}

		reportAmplitudeEvent(AmplitudeEvents.productsCTAClick, {
			button_text: 'Download Now',
			button_url: redirectUrl
		});

		window.location.href = redirectUrl;
	};

	useEffect(() => {
		const updateWindowDimensions = () => {
			if (window.innerWidth < window.innerHeight && window.innerWidth <= 900) {
				setIsPortrait(true);
			} else {
				setIsPortrait(false);
			}
		};

		updateWindowDimensions();
		window.addEventListener('resize', updateWindowDimensions);
	}, []);

	// if user is logged in we show the expanded account page in mobile if not we show the get started page
	const [expandedIndex, setExpandedIndex] = useState(auth.loggedIn ? 2 : 0);

	const sections = [
		{
			title: 'footer_title_getstarted',
			collapsed: auth.loggedIn,
			sections: [
				{ title: 'footer_getstarted_download', link: 'https://www.anghami.com/download' },
				{ title: 'footer_getstarted_product', link: 'https://www.anghami.com/products' }
			]
		},
		{
			title: 'footer_title_company',
			collapsed: true,
			sections: [
				{ title: 'footer_company_aboutus', link: 'https://www.anghami.com/about' },
				{ title: 'footer_company_team', link: 'https://www.anghami.com/team' },
				{ title: 'IR_landing_title', link: 'https://www.anghami.com/investors' },
				{ title: 'footer_company_career', link: 'https://www.anghami.com/careers' },
				{ title: 'footer_company_press', link: 'https://www.anghami.com/press' },
				{ title: 'footer_company_talks', link: 'https://talks.anghami.com/' },
				{ title: 'footer_company_kalimat', link: 'https://kalimat.anghami.com' },
				{ title: 'footer_company_terms', link: 'https://www.anghami.com/legal' }
			]
		},
		{
			title: 'footer_title_account',
			collapsed: !auth.loggedIn,
			sections: [
				{ title: 'Manage Your Account', link: 'https://www.anghami.com/manage-account' },
				{ title: 'footer_account_redeem', link: 'https://www.anghami.com/redeem' },
				{ title: 'footer_account_gift', link: 'https://www.anghami.com/gifts' },
				{ title: 'footer_account_help', link: 'https://www.anghami.com/help' }
			]
		},
		{
			title: 'footer_title_communities',
			collapsed: true,
			sections: [
				{ title: 'footer_communities_artists', link: 'https://www.anghami.com/artist-connect' },
				{ title: 'footer_communities_podcasters', link: 'https://www.anghami.com/podcasters' },
				{ title: 'footer_communities_advertisers', link: 'https://www.anghami.com/advertise' },
				{ title: 'footer_communities_affiliate', link: 'https://www.anghami.com/affiliate' },
				{
					title: 'footer_communities_corporateoffer',
					link: 'https://www.anghami.com/corporateoffer'
				},
				{
					title: 'footer_soundbox',
					link: 'https://www.anghami.com/soundbox'
				}
			]
		}
	];

	return (
		<footer className={styles.footer_container}>
			<div className={styles.footer}>
				<div className={styles.footer_left_container}>
					<div>
						<Container>
							<Row>
								{sections.map((section, index) => {
									return (
										<Col
											key={section.title}
											xs={12}
											md={6}
											lg={3}
											className={styles.footer_section_container}
										>
											<div
												className={styles.footer_section_title}
												onClick={() => {
													if (index !== expandedIndex) {
														setExpandedIndex(index);
													} else {
														setExpandedIndex(undefined);
													}
												}}
											>
												{t(section.title)}
												{isPortrait && (
													<img
														src={environment.web_cdn_url + '_next/static/icons/footer-arrow.svg'}
														alt="footer-arrow"
														width="40"
														height="40"
														className={`${styles.arrow} ${
															index === expandedIndex && styles.rottated_arrow
														}`}
													/>
												)}
											</div>
											{(expandedIndex === index || !isPortrait) && (
												<ul>
													{section.sections.map((subSection) => {
														return (
															<li key={subSection.title}>
																<a href={subSection.link}>{t(subSection.title)}</a>
															</li>
														);
													})}
												</ul>
											)}
										</Col>
									);
								})}
							</Row>
						</Container>
					</div>
				</div>
				<Container className={styles.footer_right_container}>
					<div className={styles.devices_container}>
						<AsyncImage
							imgSrc={environment.images_cdn_url + '/footer/footer-devices.png'}
							alt="Footer Image"
							width={300}
							height={100}
						/>
						<button className={styles.footer_button} type="submit" onClick={onButtonClick}>
							{t('footer_cta')}
						</button>
					</div>
					<div className={styles.footer_devices}>
						<img
							src={environment.images_cdn_url + '/footer/for-apple.png'}
							alt="Apple Anghami"
							width="40"
							height="40"
						/>
						<a
							href={iosLink}
							target="_blank"
							rel="noreferrer"
							className={styles.footer_section_title2}
						>
							{t('download_ios')} &nbsp; &#x3e;
						</a>
					</div>
					<div className={styles.footer_devices}>
						<img
							src={environment.images_cdn_url + '/footer/for-android.png'}
							alt="Android Anghami"
							width="40"
							height="40"
						/>
						<a
							href={androidLink}
							target="_blank"
							rel="noreferrer"
							className={styles.footer_section_title2}
						>
							{t('download_android')} &nbsp; &#x3e;
						</a>
					</div>
					<div className={styles.footer_devices}>
						<img
							src={environment.images_cdn_url + '/footer/for-apple.png'}
							alt="Mac Anghami"
							width="40"
							height="40"
						/>
						<a
							href={desktopAppLink}
							target="_blank"
							rel="noreferrer"
							className={styles.footer_section_title2}
						>
							{t('download_mac')} &nbsp; &#x3e;
						</a>
					</div>
					<div className={styles.footer_devices}>
						<img
							src={environment.images_cdn_url + '/footer/for-windows.png'}
							alt="Windows Anghami"
							width="40"
							height="40"
						/>
						<a
							href={desktopAppLink}
							target="_blank"
							rel="noreferrer"
							className={styles.footer_section_title2}
						>
							{t('download_web')} &nbsp; &#x3e;
						</a>
					</div>
				</Container>
			</div>
			<div className={styles.bellow_footer}>
				<Container className="no-padding">
					<Row className={styles.bellow_footer_row}>
						<Col xs={12} md={6} lg={3} className={styles.footer_bottom_row}>
							<img
								className={styles.logo}
								src={`${environment.web_cdn_url}web/assets/img/${
									lang === 'ar'
										? 'logos/Logo_Horizontal_AR_colored_DarkMode.png'
										: 'new-anghami-logos/header-logo-colored.png'
								}`}
								alt="Anghami"
								decoding="async"
							/>
						</Col>
						<Col xs={12} md={6} lg={3} className={styles.footer_bottom_row}>
							<span className={styles.footer_section_title2}>{t('language')}</span>
							<select
								className={styles.footer_language_selector}
								value={i18n.language}
								onChange={(event) => {
									const lang = event.target.value;
									setLanguageCookies(lang, null);
									setTimeout(() => {
										i18n.changeLanguage(lang, () => {
											window.location.href = updateQueryString('lang', lang, window.location.href);
										});
									}, 5);
								}}
							>
								<option value="en">English</option>
								<option value="ar">عربي</option>
								<option value="fr">Français</option>
							</select>
						</Col>
						<Col xs={12} md={6} lg={3} className={styles.footer_social_container}>
							<span className={styles.footer_section_title2}>{t('footer_social_text')}</span>
							<span className={styles.social_icons_constainer}>
								<a
									href="https://www.twitter.com/anghami"
									target="_blank"
									rel="noreferrer"
									className={styles['social-icon']}
								>
									<img
										src={environment.images_cdn_url + '/footer/twitter-logo.svg'}
										alt="Twitter Anghami"
										width="40"
										height="40"
									/>
								</a>
								<a
									href="https://www.instagram.com/anghami"
									target="_blank"
									rel="noreferrer"
									className={styles['social-icon']}
								>
									<img
										src={environment.images_cdn_url + '/footer/instagram-logo.svg'}
										alt="Instagram Anghami"
										width="40"
										height="40"
									/>
								</a>
								<a
									href="https://www.facebook.com/anghami"
									target="_blank"
									rel="noreferrer"
									className={styles['social-icon']}
								>
									<img
										src={environment.images_cdn_url + '/footer/facebook-logo.svg'}
										alt="Facebook Anghami"
										width="40"
										height="40"
									/>
								</a>
							</span>
						</Col>
						{!isPortrait && <Col xs={12} md={6} lg={3} />}
					</Row>
				</Container>
			</div>
		</footer>
	);
};

export default Footer;
